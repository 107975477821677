import React from 'react'
import { Helmet } from "react-helmet";
import { Link, graphql } from 'gatsby'
import Layout from "../components/page-objects/Layout";
import Header from '../components/page-objects/Header';
import Spacer from '../components/ui-objects/Spacer';

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <Header headerHeight="250" />
        <section className="tags">
          <Helmet title={`${tag} | ${title}`} />
          <div>
            <div>
              <h3>{tagHeader}</h3>
              <Spacer size="l" />
              <ul>
                {posts.map((post) => (
                  <li key={post.node.fields.slug}>
                    <Link to={post.node.fields.slug}>
                      {post.node.frontmatter.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <Spacer size="xxl" />
              <Link className="button secondary" to="/tags/">
                Browse all tags
              </Link>
              <Spacer size="xxl" />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
